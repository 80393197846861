import {Parametre} from '@app/models/parametre.model';
import {DateUtils} from '@app/shared/utils/date.utils';

export class MesureData{

  private _date: Date;
  private _dateExp: string;
  private _codper: number;
  private _mesure: number;
  private _type: string;          //TYPVAL => TYPE DE LA DONNEE VALIDEE
  private _detection: string;     //TYPTRA => TYPE DE DONNEES TRAITEES

  private _parametre: Parametre;

  constructor(obj: any) {
    Object.assign(this, obj);
    this._date = obj.date ? DateUtils.newDate(obj.date) : null;
    this._dateExp = DateUtils.formatDate(this._date, DateUtils.FORMAT.BE);
  }

  get date(): Date {
    return this._date;
  }

  set date(value: Date) {
    this._date = value;
  }

  get dateExp(): string {
    return this._dateExp;
  }

  set dateExp(value: string) {
    this._dateExp = value;
  }

  get codper(): number {
    return this._codper;
  }

  set codper(value: number) {
    this._codper = value;
  }

  get mesure(): number {
    return this._mesure;
  }

  set mesure(value: number) {
    this._mesure = value;
  }

  get type(): string {
    /*
      Le type peut-être :
      -   mesuré si la donnée correspond à une mesure réellement faite dans le cours d'eau
      -   calculé si la donnée a été calculée, estimée ou corrigée selon des algorithmes de validation
      -   indisponible s'il n'y a pas de mesure
     */
    //MANUEL
    //MINDIS
    //MBRUTE
    //MCORRI
    //MESTIM
    //MCALCU
    if(this._type){
      if(this._type == "MANUEL" || this._type == "MBRUTE"){
        return "Mesuré";
      }
      if(this._type == "MCALCU"){
        return "Calculé";
      }
      return this._type;
    }
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get detection(): string {
    /*
      La détection peut-être :
      -   normal si la mesure est comprise entre la limite de détection basse et la limite de détection haute
      -   < LDB si la mesure est inférieure à la limite de détection basse
      -   > LDH si la mesure est supérieure à la limite de détection haute
      -   indisponible s'il n'y a pas de mesure
     */
    //MINDIS
    //MBRUTE
    //MCORRI
    //MESTIM
    if(this._detection && this._detection != "MINDIS" && this.parametre){
      if(this.mesure < this.parametre.limdeb){
        return "< LDB";
      }else if(this.mesure > this.parametre.limdeh){
        return "> LDH";
      }else if(this.mesure >= this.parametre.limdeb && this.mesure <= this.parametre.limdeh){
        return "Normal";
      }
    }
    return this._detection;
  }

  set detection(value: string) {
    this._detection = value;
  }

  get parametre(): Parametre {
    return this._parametre;
  }

  set parametre(value: Parametre) {
    this._parametre = value;
  }
}
