import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {BaseAbstractService} from '@app/core/services/base-abstract.service';
import {MesureData} from '@app/models/mesure-data.model';
import {Parametre} from '@app/models/parametre.model';

@Injectable({
  providedIn: 'root'
})
export class MesureDataService extends BaseAbstractService{

  catalogueParam: string = null;
  catalogueDay: number = null;
  catalogueMonth: number = null;
  catalogueYear: number = null;

  get url(): string {
    return this.getApiUrl() + '/data/mesure';
  }

  async getMesure(code:string, nomsta: string, param: Parametre, dateDebut: Date, dateFin: Date): Promise<MesureData[]>{
    let data = {
      nomsta: nomsta,
      codpar: param.codpar,
      dateDebut: dateDebut,
      dateFin: dateFin
    };
    let datas = (await this.post<any>(`${this.url}/station/${code}`, data)).map(json => {
      let mesureData = new MesureData(json);
      mesureData.parametre = param;
      return mesureData;
    });
    return datas;
  }
}
